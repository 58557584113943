// -----------------------------------------------------------------------------
// This file contains variables
// -----------------------------------------------------------------------------

$text-font: 'Titillium Web', sans-serif;
$heading-font: 'Joane Stencil', sans-serif;

$primary-color: #182609;
$secondary-color: #CA9165;
$text-color: #221c18;
$text-color-gray: #6E7567;
$white: #fff;
$nav-color: #221c18;
$grey-bg: #f9fafb;
$red: #d42c2c;
$black: #000;
$green: #61b04d;

$min-width: 320px;
$max-width: 1320px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 950px,
    xl: 1120px,
    xxl: 1600px,
);

$min-vw: 320px;
$max-vw: 1400px;

$grid-columns: 60;
