.base-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    line-height: 64px;
    padding: 0 65px 0 35px;
    background-color: $primary-color;
    color: $white;
    font-size: rem(16px);
    border-radius: 30px;
    font-weight: 600;
    min-width: 195px;
    position: relative;

    .text {
        position: relative;
        opacity: 1;
        transition: opacity .3s;
        &:after {
            content: " ";
            width: 16px;
            height: 12px;
            background: url("/img/arrow-right.svg") no-repeat;
            position: absolute;
            right: -31px;
            top: 50%;
            transform: translateY(-50%);
            transition: right 0.2s ease-in-out;
        }
    }

    &:hover {
        color: $white;
        background-color: lighten($primary-color, 30%);
        .text:after {
            right: -36px;
        }
    }

    .spinner-wrap {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        opacity: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        transition: opacity .3s;
    }

    &:disabled, &.loading {
        .text {
            opacity: 0;
        }
        .spinner-wrap {
            opacity: 1;
        }
    }
}
