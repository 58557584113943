.swiper {
    position: relative;
}

.swiper-container {
    width: 100%;
}
.swiper-wrapper {
    width: 50%;
}
.swiper-slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        margin: 0;
    }
}
.slide-image {
    height: 400px;
    display: block;
    max-width: 100%;
}
