$offer-detail--gallery-gap: 20px;
$grid-gutter: 1rem;

.no-header {
    height: 196px;
}

.offer-detail {
    
    &-gallery {
        $gallery: &;

        display: flex;
        flex-wrap: wrap;

        margin-left: -#{$offer-detail--gallery-gap};
        margin-right: -#{$offer-detail--gallery-gap};

        &__main {
            flex: 1 1 100%;

            @include media-breakpoint-up(md) {
                flex: 1 1 60%;
            }

            #{$gallery}__link {
                padding-bottom: 66.67%;
            }

            .offer-detail-gallery__item {
                &:first-child {
                    padding-right: 0;
                    padding-bottom: 0;
                }
            }
        }

        &__side {
            flex: 1 1 40%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;

            @include media-breakpoint-down(md) {
                flex-direction: row;
            }

            #{$gallery}__item {
                flex: 0 1 50%;

                @include media-breakpoint-down(md) {
                    margin-top: 10px;

                    &:first-child {
                        padding-right: 5px;
                    }

                    &:last-child {
                        padding-left: 5px;
                    }
                }

                @include media-breakpoint-up(md) {
                    flex: 0 1 50%;
                }
            }

            #{$gallery}__link {
                padding-bottom: 100%;
            }
        }

        &__link {
            display: block;
            position: relative;
            height: 0;
            line-height: 0;
            overflow: hidden;

            backface-visibility: hidden;

            @media (hover: hover) {
                &:hover {
                    #{$gallery}__img {
                        transform: scale(1.2);
                        transition: transform 3s;
                    }
                }
            }

            &--video {
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 3rem;
                    height: 3rem;
                    background-image: url("../../img/icons/play.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    z-index: 1;
                    pointer-events: none;
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    background-color: #fff;
                    z-index: 1;
                    pointer-events: none;
                }
            }
        }

        &__item {
            position: relative;
            padding: $offer-detail--gallery-gap;
            backface-visibility: hidden;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }

        &__img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-width: none;

            transition: transform 1s;
            backface-visibility: hidden;
        }

        &__btn {
            display: none;
            background-color: #fff;

            position: absolute;
            bottom: 2rem;
            right: 3rem;
            z-index: 1;

            &:hover {
                color: #000;
            }

            span {
                margin-left: $grid-gutter / 3;
            }

            @include media-breakpoint-up(lg) {
                padding: 24px 34px;
                border-radius: 128px;
                color: #000;
                font-size: rem(16px);
                font-weight: 600;
                display: flex;
                align-items: center;
            }

            img {
                position: relative;
                top: -2px;
                width: 23px;
                margin-right: 5px;
            }
        }
    }

    &-content {
        margin-top: 3rem;
        color: $text-color-gray;

        &__video {
            margin-top: 3rem;
        }

        p {
            &:not(:last-child) {
                margin-bottom: 1.5rem;
            }
        }

        &__description {
            &.sliced {
                @include media-breakpoint-down(lg) {
                    height: 13em;
                    overflow: hidden;
                }
            }

            &.short {
                height: 17em !important;
                overflow: hidden !important;
            }
        }

        &__more {
            padding: $grid-gutter;
            text-align: center;
            background: linear-gradient(
                356deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 1) 37%,
                rgba(255, 255, 255, 0) 100%
            );
            @include media-breakpoint-up(lg) {
                display: none;
            }

            &.show {
                display: block !important;
            }
        }

        &__infoboxes {
            margin-bottom: 1.5rem;

            display: block;
            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    &-aside {
        @include media-breakpoint-up(lg) {
            max-width: 33rem;
            margin-left: auto;

            &--sticky {
                position: sticky;
                top: 25%;
            }
        }

        &__infoboxes {
            display: none;
            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }

    &-mortgage {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;

        color: $text-color-gray;
        font-size: rem(14);
        line-height: 1.3;
        margin-top: $grid-gutter;
        margin-bottom: -#{$grid-gutter * 0.5};

        &__value {
            font-size: rem(22);
            color: $secondary-color;
            font-weight: 500;
        }

        &__button {
            margin: $grid-gutter / 2 0;
        }

        &__content {
            margin: $grid-gutter / 2;
            margin-left: 0;
        }

        @include media-breakpoint-up(sm) {
            justify-content: space-between;
            flex-direction: row;
        }
    }
}

.offer-detail__content {
    h1 {
        color: $primary-color;
        font-size: rem(56px);
    }

    h4 {
        font-size: rem(26px);
        color: $primary-color;
    }

    p.p20 {
        color: $text-color-gray;
        font-size: rem(20px);
    }

    p {
        color: $text-color-gray;
        font-size: rem(16px);
    }
}

.offer-detail__gray-box {
    background-color: #f0f0f0;
    padding: 32px 24px;
    border-radius: 7px;

    @include media-breakpoint-up(lg) {
        margin-left: 8px;
        margin-top: 85px;
    }

    @include media-breakpoint-down(sm) {
        margin-top: 30px;
        padding: 32px 15px;
    }

    .offer-detail__gray-box-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e4e4e4;
        padding: 8px 25px 7px 12px;

        @include media-breakpoint-down(sm) {
            padding: 8px 8px;
        }

        &:last-child {
            border-bottom: none;
        }

        span {
            &:first-child {
                text-transform: uppercase;
                font-weight: 600;
                color: $primary-color;
            }

            &:last-child {
                color: #000;
                font-size: rem(20px);
                text-align: end;
            }
        }
    }
}

.offer-detail__price-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary-color;
    padding: 16px 24px;
    color: #fff;
    border-radius: 7px;
    margin-top: 12px;

    @include media-breakpoint-up(lg) {
        margin-left: 8px;
    }

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
        padding: 19px 24px 6px;
    }

    span {
        &:first-child {
            text-transform: uppercase;
            font-size: rem(16px);
            font-weight: 600;
        }

        &:last-child {
            font-size: rem(36px);
        }
    }
}

.offer-detail__broker {
    padding: 24px 32px;
    background: #ffffff;
    border: 1px solid #e4e4e4;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    margin-top: 40px;

    img {
        max-width: 100%;
    }

    h3 {
        font-size: rem(26px);
        color: #000;
    }

    a {
        font-weight: 300;
        font-size: rem(22px);
    }

    @include media-breakpoint-up(lg) {
        margin-left: 8px;
    }

    @media (max-width: 460px) {
        padding: 20px;

        .img-broker {
            width: 60px;
        }

        h3 {
            font-size: rem(21px);
            color: #000;
        }

        a {
            font-weight: 300;
            font-size: rem(17px);
        }
    }
}

.offer-detail-section {
    margin-top: 100px;
    background-color: #f0f0f0;
    padding: 100px 0 120px;
}

h3 {
    &.offer-detail__h {
        font-size: rem(40px);
        margin-bottom: 40px;
        color: $primary-color;
    }
}

@media (min-width:992px) {
    .sticky-offer-detail {
        position: sticky;
        top: 20px;
    }

    .sticky-col-h {
        height: auto;
    }
}
