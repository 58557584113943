header {
    padding: 33px 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9999;
    button {
        position: absolute;
        right: 15px;
        top: 10px;
        &:hover {
            opacity: 0.7;
        }
    }

    nav {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        text-align: center;
        background: rgba($white, 0.8);
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        &.is-open {
            opacity: 1;
            z-index: 999;
            visibility: visible;
            ul {
                opacity: 1;
            }
            &:before {
                transform: skew(-14deg) translateX(0);
                @include media-breakpoint-down(sm) {
                    transform: skew(-8deg) translateX(0);
                }
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: -15px;
            background: $primary-color;
            transform-origin: 0 0;
            transform: skew(-14deg) translateX(-120%);
            transition: all 0.3s 0.1s linear;
        }

        ul {
            position: relative;
            z-index: 999;
            opacity: 0;
            transition: opacity 0.2s 0.2s ease-in-out;
            li {
                margin: 10px 0;
            }
            a {
                color: $white;
                font-size: rem(30px);
                position: relative;
                display: inline-block;
                padding: 5px 0;
                @include media-breakpoint-down(sm) {
                    font-size: rem(20px);
                }
                &:after {
                    content: " ";
                    width: 0;
                    height: 1px;
                    background-color: $white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transition: width 0.3s ease-in-out;
                }
                &:hover {
                    opacity: 0.3;
                    color: $white;
                    &:after {
                        width: 100%;
                    }
                }
            }
        }
        button {
            position: absolute;
            top: 30px;
            right: 50px;
            z-index: 999;
            img {
                width: 40px;
            }
        }
    }
}
.logo-height{
    min-height: 200px;
}
@media screen and (max-width: 375px) {
    .logo-height{
        min-height: 160px;
    }
}
