#developerske-projekty {
    .all-offers-fade {
        width: 100%;
        left: 0;
        right: 0;
        height: 91px;
        position: relative;
        overflow: hidden;
    }

    .all-offers-fade:after {
        content: " ";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        background: linear-gradient(to top, #fff, #ffffff9c);
    }
}

.developerske-projekty-card {
    border-radius: 7px;

    h4 {
        color: $text-color;
        font-size: rem(36px);
        margin-bottom: 0;
        margin-top: 23px;
    }

    small {
        font-size: 18px;
        color: #798073;
    }

    &:hover {
        h4 {
            color: $secondary-color;
        }

        small {
            color: $secondary-color;
        }
    }

    .card-img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        object-position: center;
    }
}
