.long-text-wrap {
    //


    p, span, a {
        color: inherit !important;
    }

    h1, h2, h3, h4, h5, h6 {
        color: inherit !important;
    }
}

.ck-heading {

    .__text-small {
        @extend .h6;
    }

    .__text-normal {
        font-size: 1rem;
        line-height: normal;
    }

    .__text-big {
        @extend .h5;
    }

    .__text-xl {
        @extend .h4;
    }

    .__text-nadpis-3 {
        @extend .h3;
    }

    .__text-nadpis-2 {
        @extend .h2;
    }

    .__text-nadpis-1 {
        @extend .h1;
    }

}
