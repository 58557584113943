#o-nas, #nase-sluzby {
    h2 {
        @include fluid-font($min-vw, $max-vw, 25px, 56px);
        line-height: 1;
        margin-bottom: 43px;
    }

    p {
        @include fluid-font($min-vw, $max-vw, 18px, 24px);
        color: $text-color-gray;
        font-weight: 400;
    }

    ul {
        @include fluid-font($min-vw, $max-vw, 18px, 24px);
        color: $text-color-gray;
        font-weight: 400;
    }
}

.gray-section {
    background-color: #f0f0f0;
    padding: 120px 0;
    margin-bottom: 104px;

    h2 {
        @include fluid-font($min-vw, $max-vw, 25px, 56px);
        line-height: 1;
        margin-bottom: 43px;
    }

    p {
        @include fluid-font($min-vw, $max-vw, 18px, 24px);
        color: $text-color-gray;
        font-weight: 400;
    }
}

.pr-10 {

    @include media-breakpoint-up(lg) {
        padding-right: 100px;
    }

}

.pl-10 {

    @include media-breakpoint-up(lg) {
        padding-left: 100px;
    }
}

.broker-card {
    max-width: 100%;
    width: 100%;

    .broker-card-img-wrap {
        border-radius: 7px;
        width: 100%;
        max-width: 100%;
        height: 25rem;
        overflow: hidden;

        .broker-card-img {
            min-width: 100%;
            min-height: 100%;
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }


    h4 {
        color: $text-color;
        font-size: rem(36px);
        margin-bottom: 0;
        margin-top: 23px;
        transition: color .3s;
    }

    .contact-text {
        display: block;
        font-size: 18px;
        color: #798073;
        transition: color .2s;

        a {
            color: inherit;
        }

        &:hover {
            color: $secondary-color;
        }
    }
}
