@include font-face('Joane Stencil', '../fonts/joanestencil/joane-stencil-bold-webfont', 700, normal);

html,
body {
    overflow-x: clip;
}

.scroll-lock {
    overflow: hidden;
}

body {
    margin: 0;
    background-color: $white;
    font-family: $text-font !important;
}

* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4 {
    font-weight: 600;
    font-family: $heading-font !important;
}

h4 {
    font-size: rem(26px);
}

ul {
    margin: 0 !important;
}

button {
    border: none;
    background: none;
}

a {
    text-decoration: none !important;
}

a,
button {
    transition: all 0.2s ease-in-out;
}

input,
textarea {
    padding: 16px 17px;
    background: #ffffff;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    width: 100%;
}

.w-full {
    width: 100%;
}

.text-center {
    text-align: center;
}

.container {
    position: relative;
}

.white-bg-section {
    background-color: $white;
    padding: 40px 0;
}

section h3 {
    font-size: rem(30px);
    font-weight: 600;
}

section h2 {
    @include fluid-font($min-width, $max-width, 28px, 50px);
    color: $text-color;
}

section p {
    // color: rgba(12, 12, 12, 0.5);
    line-height: 1.7;
}

.text-capitalize {
    text-transform: capitalize;
}

.font-joane-stencil {
    font-family: 'Joane Stencil', sans-serif;
}
