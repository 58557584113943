.footer-top {
    background-color: $primary-color;
    padding: 36px 0;

    .logo {
        img {
            max-width: 139px;
        }
    }

    li {
        a {
            color: $secondary-color;
            font-weight: 500;
            font-size: 16px;
        }
    }
}
