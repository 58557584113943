@keyframes separatorIcon {
    0% {
        opacity: 1;
        transform: translate(-50%, -52%);
    }
    100% {
        opacity: 0;
        transform: translate(-50%, -47%);
    }
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
