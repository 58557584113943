/**
    HERO SECTION
**/
.hero {
    height: 994px;
    background-color: $primary-color;
    background-image: url("/img/hero-bg-img.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    @include media-breakpoint-down(lg) {
        height: auto;
        padding: 215px 0 50px 0;
    }
    .container {
        height: 100%;
    }

    h1 {
        max-width: 634px;
        margin: 0 auto -70px auto;
        color: #ffeddf;
        text-align: center;
        line-height: 1;
        @include media-breakpoint-down(lg) {
            margin: 0 auto;
        }
        @include fluid-font($min-vw, $max-vw, 28px, 72px);
    }

    .call-info {
        background-color: $white;
        border-radius: 16px;
        padding: 30px 40px 20px 40px;
        max-width: 414px;
        box-shadow: 0 12px 12px 0 rgba($black, 0.14);
        position: absolute;
        bottom: 65px;
        right: 15px;
        @include media-breakpoint-down(lg) {
            position: relative;
            right: 0;
            bottom: 0;
            width: 100%;
            max-width: 100%;
        }
        h5 {
            font-size: rem(22px);
            color: $black;
        }
        a {
            font-weight: 300;
            font-size: rem(22px);
            color: #0075ff;
            text-decoration: underline !important;
        }
    }
}

.hero-page {
    height: 502px;
    background-color: $primary-color;
    background-image: url("/img/vybrane-ponuky.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    @include media-breakpoint-down(lg) {
        height: auto;
        padding: 215px 0 50px 0;
    }
    .container {
        height: 100%;
    }

    h1 {
        max-width: 1200px;
        margin: 0 auto -70px auto;
        color: #ffeddf;
        text-align: center;
        line-height: 1;
        @include media-breakpoint-down(lg) {
            margin: 0 auto;
        }
        @include fluid-font($min-vw, $max-vw, 28px, 72px);
    }
}

/**
ABOUT US SECTION
**/
.about-us-section {
    background-color: #f0f0f0;
    padding: 120px 0;
    h2 {
        @include fluid-font($min-vw, $max-vw, 25px, 56px);
        line-height: 1;
        margin-bottom: 43px;
    }

    p {
        @include fluid-font($min-vw, $max-vw, 18px, 24px);
        color: $text-color-gray;
        font-weight: 400;
    }
}

/**
    OFFERS
**/

.offers {
    // padding: 100px 0;

    .offers-row {
        position: relative;
    }

    .base-btn {
        position: relative;
        z-index: 15;
    }

    .all-offers-fade {
        width: 100%;
        left: 0;
        right: 0;
        height: 222px;
        position: absolute;
        bottom: -140px;
        overflow: hidden;
        .content {
            visibility: hidden;
        }
        &:after {
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            background: linear-gradient(
                to top,
                rgba(255, 255, 255, 1),
                rgba(255, 255, 255, 0.9)
            );
        }
    }
    h3 {
        padding-right: 100px;
        @include fluid-font($min-vw, $max-vw, 25px, 40px);
        color: $black;
    }

    p {
        color: $text-color-gray;
        font-size: rem(20px);
        @include fluid-font($min-vw, $max-vw, 16px, 20px);
        line-height: 1.65;
        font-weight: 400;
    }

    .offer-card {
        position: relative;

        a {
            position: relative;
            display: block;
            aspect-ratio: 1/0.7;
            border-radius: 7px;
            overflow: hidden;
            &:after {
                content: " ";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                transition: all 0.3s ease-in-out;
                background: linear-gradient(
                    to top,
                    rgba(0, 0, 0, 0.6),
                    rgba(0, 0, 0, 0)
                );
                z-index: 1;
            }

            &:before {
                content: " ";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                transition: all 0.3s ease-in-out;
                background: linear-gradient(
                    to top,
                    rgba(24, 38, 9, 0.7),
                    rgba(24, 38, 9, 0.2)
                );
                z-index: 1;
                opacity: 0;
                @include media-breakpoint-only(xs) {
                    opacity: 1;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .content {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                color: $white;
                padding: 48px 20px 48px 48px;
                transform: translateY(51px);
                transition: transform 0.3s ease-in-out;
                @include media-breakpoint-only(xl) {
                    padding: 34px 20px 20px 41px;
                    h4 {
                        font-size: rem(18px) !important;
                    }

                    span {
                        font-size: rem(25px) !important;
                    }
                }

                @include media-breakpoint-only(xs) {
                    transform: translateY(0);
                    padding: 34px 20px 20px 20px;
                    h4 {
                        font-size: rem(16px) !important;
                    }

                    span {
                        font-size: rem(20px) !important;
                    }
                }
                small {
                    font-size: rem(14px);
                    font-weight: 700;
                }

                h4 {
                    font-size: rem(24px);
                    font-weight: 700;
                    margin: 10px 0;
                    line-height: 1.1;
                }
                span {
                    font-size: rem(32px);
                    font-weight: 400;
                }

                button {
                    display: block;
                    color: $white;
                    font-weight: 700;
                    position: relative;
                    padding-left: 82px;
                    margin-top: 20px;
                    opacity: 0;
                    transform: translateX(20px);
                    transition: all 0.3s ease-in-out;
                    @include media-breakpoint-only(xl) {
                        margin-top: 10px;
                    }

                    @include media-breakpoint-only(xs) {
                        margin-top: 10px;
                        opacity: 1;
                        transform: translateX(0);
                    }
                    &:before {
                        content: " ";
                        width: 62px;
                        height: 51px;
                        background-color: $white;
                        border-radius: 30px;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        background-image: url("/img/arrow-right-dark.svg");
                        background-position: center center;
                        background-repeat: no-repeat;
                        @include media-breakpoint-only(xl) {
                            height: 34px;
                        }

                        @include media-breakpoint-only(xs) {
                            height: 25px;
                        }
                    }
                }
            }

            &:not(.disabled) {
                &:hover,
                &:focus {
                    &:after {
                        opacity: 0;
                        background: linear-gradient(
                            to top,
                            rgba(24, 38, 9, 0.6),
                            rgba(24, 38, 9, 0)
                        );
                    }
                    &:before {
                        opacity: 1;
                    }
                    .content {
                        transform: translateY(0);
                    }
                    button {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }

            .labels {
                position: absolute;
                top: 20px;
                right: 20px;
                @include media-breakpoint-down(sm) {
                    top: 10px;
                    right: 10px;
                }
                &:not(.all-offers-fade .labels) {
                    z-index: 15;
                }
                li {
                    height: 43px;
                    line-height: 43px;
                    border-radius: 30px;
                    padding: 0 20px;
                    color: $white;
                    font-weight: 700;
                    font-size: rem(14px);
                    @include media-breakpoint-only(xl) {
                        height: 30px;
                        line-height: 30px;
                    }
                    @include media-breakpoint-only(xs) {
                        height: 30px;
                        line-height: 30px;
                    }

                    @include media-breakpoint-down(sm) {
                        font-size: rem(12px);
                    }
                    &:not(.type) {
                        backdrop-filter: blur(5px);
                        background-color: rgba(0, 0, 0, 0.2);
                    }

                    &.type {
                        &.black {
                            background-color: $primary-color;
                        }
                        &.red {
                            background-color: #b20808;
                        }
                        &.orange {
                            background-color: #ca9165;
                        }
                    }
                }
            }
        }
    }
}

.sold-label {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 2;
    background: #eb5757;
    box-shadow: 0px 11px 21px rgb(0 0 0 / 11%);
    border-radius: 128px;
    font-weight: 600;
    font-size: 24px;
    padding: 10px 20px;
}

#offers-sold {
    /*.offer-card {
        a {
            &:after {
                background: linear-gradient(0deg, #eb5757bf, #eb5757bf);
            }
        }
    } */

    .sold-label {
        display: block;
    }
}

.offers-home {
    padding: 100px 0;

    h3 {
        padding-right: 100px;
        color: #000;
        font-size: rem(40px);
    }

    p {
        font-size: rem(20px);
        color: #6e7567;
    }
}

.section-pad {
    padding: 100px 0;
}

/**
    SERVICES
**/

.services {
    padding: 100px 0;
    position: relative;
    z-index: 15;
    background-color: rgba($primary-color, 0.9);
    background-image: url("/img/services-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: $white;
    h3 {
        @include fluid-font($min-vw, $max-vw, 20px, 42px);
    }

    h4 {
        margin: 15px 0;
    }

    p {
        color: #fafafa;
        opacity: 0.7;
        line-height: 1.65;
        font-size: rem(18px);
        font-weight: 400;
    }

    .img-circle {
        width: 12.5rem;
        height: 12.5rem;
        background-color: $primary-color;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;

        .img-cover {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
}

/**
CONTACT SECTION
**/

#contact {
    padding: 100px 0;
    margin-top: 40px;

    h3 {
        font-size: rem(36px);
    }

    h4 {
        font-size: rem(26px);
    }

    p {
        color: $text-color-gray;
        font-size: rem(18px);
    }

    a {
        font-size: rem(18px);
    }

    textarea {
        height: 122px;
    }

    .text-black {
        color: $text-color !important;
    }
}

.glide__slide {
    img {
        box-shadow: 0px 21px 28px rgba(0, 0, 0, 0.15);
        border-radius: 7px;
    }
}
