.social-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    padding: 14px;
    background-color: $primary-color;
    color: $white;
    border-radius: 30px;
    position: relative;
    overflow: hidden;

    &:hover {
        color: $white;
        background-color: lighten($primary-color, 30%);
    }

    img {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        height: auto;

    }
}
