// -----------------------------------------------------------------------------
// This file contains mixins
// -----------------------------------------------------------------------------

// PX to REM
$html-font-size: 16px;


@function stripUnit($value) {
    @return ($value / ($value * 0 + 1));
}

@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

// Fluid font
@mixin fluid-font($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{stripUnit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{stripUnit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

// Placeholder
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}
@mixin autocomplete {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        @content;
    }
}

// Font face
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}
